import { SlideImage } from './types';

const attackImages: SlideImage[] = [
  {
    imageName: 'add-attack/0.jpg',
    caption: 'Tutorial - How to add attack tasks',
  },
  {
    imageName: 'add-attack/0.jpg',
    caption: 'TW Timer supports several attack scripts - ',
  },
  {
    imageName: 'add-attack/1.jpg',
    caption: 'Single Village Planner,',
  },
  {
    imageName: 'add-attack/2.jpg',
    caption: 'Mass Attack Planner,',
  },
  {
    imageName: 'add-attack/3.jpg',
    caption: "Devil's Tribal Wars Utilities Planner,",
  },
  {
    imageName: 'add-attack/4.jpg',
    caption: 'and Fodox Mass Attack Planner.',
  },
  {
    imageName: 'add-attack/5.jpg',
    caption: 'To add attack tasks, click the Add button,',
  },
  {
    imageName: 'add-attack/6.jpg',
    caption: 'fill in the form',
  },
  {
    imageName: 'add-attack/7.jpg',
    caption: 'and paste the plan into the textarea.',
  },
  {
    imageName: 'add-attack/8.jpg',
    caption: 'Your attacks should appear as timed tasks.',
  },
  {
    imageName: 'add-attack/9.jpg',
    caption: 'If you pasted the wrong format',
  },
  {
    imageName: 'add-attack/10.jpg',
    caption: 'you will see this message',
  },
  {
    imageName: 'add-attack/11.jpg',
    caption: 'so make sure to copy the right format!',
  },
  {
    imageName: 'add-attack/12.jpg',
    caption: "Here's an example with the SV Planner:",
  },
  {
    imageName: 'add-attack/13.jpg',
    caption: 'Copy the plan from here,',
  },
  {
    imageName: 'add-attack/14.jpg',
    caption: 'in the form, select a world,',
  },
  {
    imageName: 'add-attack/15.jpg',
    caption: 'choose the attack type,',
  },
  {
    imageName: 'add-attack/16.jpg',
    caption: 'then a subtype - ',
  },
  {
    imageName: 'add-attack/17.jpg',
    caption: 'it will show in the task message -',
  },
  {
    imageName: 'add-attack/18.jpg',
    caption: 'the offset will trigger the alarm earlier',
  },
  {
    imageName: 'add-attack/19.jpg',
    caption: 'and finally paste the plan here.',
  },
  {
    imageName: 'add-attack/20.jpg',
    caption: "and it's done",
  },
  {
    imageName: 'add-attack/21.jpg',
    caption: "Now let's use the Mass Attack Planner.",
  },
  {
    imageName: 'add-attack/22.jpg',
    caption: 'Copy the plan from here,',
  },
  {
    imageName: 'add-attack/23.jpg',
    caption: 'fill in the form, paste it',
  },
  {
    imageName: 'add-attack/24.jpg',
    caption: 'and done.',
  },
  {
    imageName: 'add-attack/25.jpg',
    caption: "For Devil's Attack Planner:",
  },
  {
    imageName: 'add-attack/26.jpg',
    caption: 'copy the plan from here,',
  },
  {
    imageName: 'add-attack/27.jpg',
    caption: 'paste it,',
  },
  {
    imageName: 'add-attack/28.jpg',
    caption: 'and tasks are added.',
  },
  {
    imageName: 'add-attack/29.jpg',
    caption: 'With Fodox Mass Attack Planner',
  },
  {
    imageName: 'add-attack/30.jpg',
    caption: 'make sure you select the right format',
  },
  {
    imageName: 'add-attack/31.jpg',
    caption: 'and same as before',
  },
  {
    imageName: 'add-attack/32.jpg',
    caption: "it's done!",
  },
];

export { attackImages };
